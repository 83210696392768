import React, { useEffect, useRef } from "react"
import { PortableText, PortableTextReactComponents } from "@portabletext/react"
import cn from "classnames"
import s from "./RichText.module.scss"
import { SanityRichText } from "../../sanity/types"
import { LinkHandler } from "../link-handler/LinkHandler"
import Icon from "../icon/Icon"

interface RichTextProps {
    blocks: SanityRichText
    className?: string
    tag?: keyof JSX.IntrinsicElements
}

const components: Partial<PortableTextReactComponents> = {
    block: {
        h1: ({ children }) => <h1 className="heading-300 balance-text light-font-weight">{children}</h1>,
        h2: ({ children }) => <h1 className="heading-200 balance-text light-font-weight">{children}</h1>,
        h3: ({ children }) => <h1 className="heading-100 balance-text light-font-weight">{children}</h1>,
        h4: ({ children }) => <h1 className="heading-50 balance-text light-font-weight">{children}</h1>,
    },
    // ({ value, children }) => {
    //     if (/^h\d/.test(value.style)) {
    //         const text = getFirstTextNode({ children })
    //         const headingMap = {
    //             "h1": "heading-300",
    //             "h2": "heading-200",
    //             "h3": "heading-100",
    //             "h4": "heading-50",
    //         }
    //         return React.createElement(value.style, { id: slugify(text), className: "light-font-weight " + headingMap[value.style] }, children)
    //     }

    //     return <p>{children}</p>
    // },
    marks: {
        link: ({ value, children }) => {
            if (!value.linkType) {
                return children
            }

            return <LinkHandler link={value}>{children}</LinkHandler>
        },
        colored: ({ children }) => <span className="colored">{children}</span>,
        light: ({ children }) => <span className="light-font-weight">{children}</span>,
        icon: ({ value }) => {
            if (!value.icon) {
                return null
            }
            return (
                <>
                    <Icon type={value.icon} className="icon" />
                </>
            )
        },
    },
}

export default function RichText({ blocks: initBlocks, className, tag }: RichTextProps) {
    const blocks = useRef(initBlocks)

    useEffect(() => {
        if (typeof window !== "undefined" && initBlocks && window.location.search) {
            // Replace moustache tags in blocks with query parameters
            const parameters = Object.fromEntries(new URLSearchParams(window.location.search))
            // parameters to object
            blocks.current = initBlocks.map(block => {
                if (block._type === "block" && block.children) {
                    block.children = block.children.map(child => {
                        if (child.text?.includes("{{")) {
                            parameters && Object.keys(parameters).forEach(key => {
                                child.text = child.text.replace("{{" + key + "}}", parameters[key])
                            })
                        }
                        return child
                    })
                }
                return block
            })
        }
    }, [])

    if (!blocks.current || blocks.current.length === 0) {
        return null
    }

    const Tag = tag || "div"

    return (
        <Tag className={cn(s["rich-text"], className)}>
            <PortableText value={blocks.current} components={components} />
        </Tag>
    )
}
