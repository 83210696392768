import React, { useEffect, useRef, useState } from "react"
import { GlobalSettings, GlobalSettingsLinkGroup, LinkItem } from "src/sanity/types"
import s from "./MobileNavigation.module.scss"
import cn from "classnames"
import Icon, { IconType } from "src/components/icon/Icon"
import SegmentSelector from "./SegmentSelector"
import { createFocusTrap } from "focus-trap"
import { useSettings } from "../page/settingsContext"
import { LinkHandler } from "../../components/link-handler/LinkHandler"
import { useListener } from "react-bus"
import LanguageSelector from "src/components/language-selector/LanguageSelector"
import { LinkDisplay } from "./Menu"

interface MobileNavigationProps {
    close: () => void
    menuOpen: boolean
    loginLink: LinkItem
    navigation: GlobalSettings["navigation"]["header"]
}

export default function MobileNavigation({ close, menuOpen, loginLink, navigation }: MobileNavigationProps) {
    const [submenu, setSubmenu] = useState<(LinkItem | GlobalSettingsLinkGroup)>()
    const [submenuOpen, setSubmenuOpen] = useState(false)
    const [mobileMenuOpen, setMobileMenuOpen] = useState(menuOpen)
    const refInner = useRef<HTMLDivElement>()
    const refOuter = useRef<HTMLDivElement>()
    const menuRef = useRef<HTMLDivElement>()
    const { translations: t } = useSettings()

    useListener("loginOverlay", () => {
        setMobileMenuOpen(false)
    })

    useEffect(() => {
        if (menuRef.current) {
            if (mobileMenuOpen) {
                menuRef.current.style.opacity = "1"
            } else {
                menuRef.current.style.opacity = "0"
                const timer = setTimeout(() => {
                    close()
                }, 300)
                return () => clearTimeout(timer)
            }
        }
    }, [mobileMenuOpen])

    useEffect(() => {
        if (submenuOpen) {
            const trap = createFocusTrap(refInner.current, { onDeactivate: () => setSubmenuOpen(false) })

            trap.activate()

            return () => {
                trap.deactivate()
            }
        }
    }, [submenuOpen])

    return (

        <nav ref={menuRef} className={cn(s["mobile-nav"], { [s["mobile-nav--submenu-open"]]: submenuOpen })}>
            <div ref={refOuter} className={cn(s["mobile-nav__panel"], s["mobile-nav__panel--primary"])}>
                <div className={s["mobile-nav__top"]}>
                    <SegmentSelector />

                    <div>
                        <button
                            className={s["mobile-nav__close"]}
                            onClick={() => {
                                setMobileMenuOpen(false)
                            }}
                        >
                            <Icon type={IconType.X_THIN} />
                            <span className="visually-hidden">{t?.generic?.close}</span>
                        </button>
                    </div>
                </div>

                <div className={s["mobile-nav__main"]}>
                    <ul className={cn(s["mobile-nav__list"], s["mobile-nav__list--large"])}>
                        {[...(navigation), ...(loginLink ? [loginLink] : [])]?.map(i => {
                            if (i.type === "linkGroup") {
                                return (
                                    <li key={i.id}>
                                        <button
                                            className=""
                                            onClick={() => {
                                                setSubmenu(i)
                                                setSubmenuOpen(true)
                                            }}
                                        >
                                            {i.label}
                                            <Icon type={IconType.CHEVRON_RIGHT} />
                                        </button>
                                    </li>
                                )
                            }

                            return (
                                <li key={i.id}>
                                    <LinkHandler link={i} />
                                </li>
                            )
                        })}
                    </ul>
                </div>

                <div className={s["mobile-nav__bottom"]}>
                    <LanguageSelector />
                </div>
            </div>

            {/* Submenu overlay */}
            <div ref={refInner} aria-hidden={!submenuOpen} className={cn(s["mobile-nav__panel"], s["mobile-nav__panel--secondary"])}>
                <div className={s["mobile-nav__top"]}>
                    <button
                        className={cn(s["mobile-nav__back"], "arrow-link--left")}
                        onClick={() => {
                            setSubmenuOpen(false)
                        }}
                    >
                        <Icon type={IconType.ARROW_LEFT} />
                        {t?.generic?.back}
                    </button>

                    <button
                        className={cn(s["mobile-nav__active-submenu"])}
                        onClick={() => {
                            setSubmenuOpen(false)
                        }}
                    >
                        {submenu && submenu?.type === "linkGroup" && submenu.label}
                    </button>

                    <div>
                        <button
                            className={s["mobile-nav__close"]}
                            onClick={() => {
                                setMobileMenuOpen(false)
                            }}
                        >
                            <Icon type={IconType.X_THIN} />
                            <span className="visually-hidden">{t?.generic?.close}</span>
                        </button>
                    </div>
                </div>

                <div className={cn(s["mobile-nav__main"], s["mobile-nav__main--flex-start"])}>
                    <ul className={cn(s["mobile-nav__sub-list"])}>
                        {submenu && "children" in submenu && submenu.children.map((i) => {
                            return <LinkDisplay menuItem={i} key={i.id} delay={"0"} big={submenu?.children?.length > 1} />
                        })}
                    </ul>
                </div>
            </div>
        </nav>
    )
}

