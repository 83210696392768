import cn from "classnames"
import React, { ReactNode } from "react"

import s from "./Container.module.scss"

interface ContainerProps {
    children: ReactNode
    className?: string
    narrow?: boolean
    noMargin?: boolean
    id?: string
}

const Container = React.forwardRef(function Container({
    children,
    narrow,
    className,
    noMargin = false,
    id,
}: ContainerProps, ref: React.Ref<HTMLDivElement>)  {
    return (
        <div ref={ref} className={cn(s["container"], { [s["container--narrow"]]: narrow, [s["container--no-margin"]]: noMargin  }, className)} id={id} >
            {children}
        </div>
    )
})

export { Container }
