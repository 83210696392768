import dynamic from "next/dynamic"
import { GlobalSettings, Locale, PageThemeType, SanityCallToAction } from "../sanity/types"
import * as selectors from "../sanity/selectors"
import { FrontpageDocument } from "./frontpage"
import { PageDocument } from "./page"
import { BlogOverviewDocument } from "./blog/blogOverview"
import { BlogPostDocument } from "./blog/blogPost"
import { InAppPageDocument } from "./inAppPage/inAppPage"

export type BaseQueryProps = {
    id: string
    headerCTAOverwrite: SanityCallToAction
    pageThemeType?: PageThemeType
}

export type DocTypeMap = {
    frontpage: FrontpageDocument
    page: PageDocument
    blogOverview: BlogOverviewDocument
    blogPost: BlogPostDocument
    inAppPage: InAppPageDocument
}

export type TemplateType = keyof DocTypeMap

export type BaseProps = {
    initialData: Sanity.Schema.Frontpage
    globalSettings: GlobalSettings
    docType: TemplateType
    preview: boolean
    slug: string
    resolvedUrl: string
    locale: Locale
}

export const DocQuery: { readonly [Key in TemplateType]: string } = {
    frontpage: selectors.frontpage,
    page: selectors.page,
    blogOverview: selectors.blogOverview,
    blogPost: selectors.blogPost,
    inAppPage: selectors.inAppPage,
}

export type TemplateComponent<PageData> = React.ComponentType<{ pageData: PageData }>

export const TemplateMap: {
    [Key in TemplateType]: TemplateComponent<DocTypeMap[Key]>
} = {
    frontpage: dynamic(import("./frontpage")),
    page: dynamic(import("./page")),
    blogOverview: dynamic(import("./blog/blogOverview")),
    blogPost: dynamic(import("./blog/blogPost")),
    inAppPage: dynamic(import("./inAppPage/inAppPage")),
}
